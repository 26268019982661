import "core-js/modules/es.array.concat.js";
import StyledTabs from "~/components/StyledTabs";
import useBaseUtils from "~/functions/useBaseUtils";
export default {
  __name: 'ownership',
  emits: ["closechart"],
  setup: function setup(__props, _ref) {
    var emit = _ref.emit;
    var _useBaseUtils = useBaseUtils(),
      refCode = _useBaseUtils.refCode,
      sectionWrapperStyle = _useBaseUtils.sectionWrapperStyle;
    var _useNuxtApp = useNuxtApp(),
      $store = _useNuxtApp.$store;
    var ticker = computed(function () {
      return $store.state.ciq.ticker;
    });
    var ownershipTabs = [{
      label: "Shareholders",
      slug: "multi",
      nuxt: true,
      exact: true,
      to: "/stock/ownership?cid=".concat(ticker.value.companyid, "&tid=").concat(ticker.value.tradingitemid, "&ref=").concat(refCode.value)
    }, {
      label: "Insider Transactions",
      slug: "street",
      nuxt: true,
      to: "/stock/ownership/insiders?cid=".concat(ticker.value.companyid, "&tid=").concat(ticker.value.tradingitemid, "&ref=").concat(refCode.value)
    }];
    onMounted(function () {
      emit("closechart");
    });
    return {
      __sfc: true,
      emit: emit,
      refCode: refCode,
      sectionWrapperStyle: sectionWrapperStyle,
      $store: $store,
      ticker: ticker,
      ownershipTabs: ownershipTabs,
      StyledTabs: StyledTabs
    };
  }
};